<template>
    <div class="flex flex-col items-center min-h-screen pt-6 bg-gray-100 sm:justify-center sm:pt-0">
        <div>
            <div class="flex justify-center w-auto h-auto">
                <slot name="logo" />
            </div>
            <h1 class="mt-12 text-4xl font-bold text-center text-gray-900">
                <slot name="title" />                
            </h1>
            <div class="flex justify-center">
                <slot name="progress" />
            </div>
        </div>

        <div class="w-full px-6 py-4 mt-6 mb-16 overflow-visible bg-white shadow-md sm:max-w-md sm:rounded-lg">
            <slot />
        </div>
    </div>
</template>
